<template>
  <div>
    <h3>Metas e conquistas</h3>
    <b-row class="d-flex justify-content-between mt-2">
      <b-col
        v-for="badge in dashBadges"
        :key="badge.id"
        class="d-flex justify-content-center"
        @click="showModal(badge.id)"
        style="flex: 1; padding: 0 5px; cursor: pointer"
      >
        <b-card class="card-placeholder text-center">
          <h4 class="badge-title">
            {{ badge.title }}
          </h4>
          <img :src="badge.image" alt="Badge Image" class="card-image" />
        </b-card>
      </b-col>
      <!-- Modal para exibir os detalhes do badge -->
      <BadgesModal v-model="showModalFlag" :badgeId="selectedBadgeId" />
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import BadgesModal from "./BadgesModal.vue";
import { badgesDataDefault } from "@/constants/planner_gamification_badges_types";
import { isPnRole } from "@/constants/auth";

export default {
  components: {
    BadgesModal,
  },
  data() {
    return {
      showModalFlag: false,
      selectedBadgeId: null,
      badgesData: badgesDataDefault,
    };
  },
  computed: {
    ...mapGetters({
      appointmentBadges: types.APPOINTMENT_BADGES,
      subscriptionBadges: types.SUBSCRIPTION_BADGES,
      roleBadges: types.ROLE_BADGES,
      epBadges: types.EP_BADGES,
      totalEarningsBadges: types.TOTAL_EARNINGS_BADGES,
      consortiumBadges: types.CONSORTIUM_BADGES,
      lifeTotalsBadges: types.LIFE_TOTALS_BADGES,
    }),
    dashBadges() {
      return this.badgesData;
    },
    isPn: function () {
      return isPnRole(this.user?.user_role_id);
    },
  },
  methods: {
    ...mapActions({
      getAppointmentBadges: types.GET_APPOINTMENT_BADGES,
      getSubscriptionBadges: types.GET_SUBSCRIPTION_BADGES,
      getRoleBadges: types.GET_ROLE_BADGES,
      getEpBadges: types.GET_EP_BADGES,
      getTotalEarningsBadges: types.GET_TOTAL_EARNINGS_BADGES,
      getConsortiumBadges: types.GET_CONSORTIUM_BADGES,
      getLifetotalsBadges: types.GET_LIFE_TOTALS_BADGES,
    }),
    getAllBadges() {
      this.getRoleBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateRoleBadgeImage();
        });
      this.getAppointmentBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateAppointmentBadgeImage();
        });
      this.getSubscriptionBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateSubscriptionBadgeImage();
        });
      this.getEpBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateEpBadgeImage();
        });
      this.getLifetotalsBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateVidaBadgeImage();
        });
      this.getConsortiumBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateConsortiumBadgeImage();
        });
      this.getTotalEarningsBadges()
        .catch((error) => {})
        .finally(() => {
          this.updateTotalEarningsBadgeImage();
        });
    },
    updateRoleBadgeImage() {
      const medalhaRole = this.roleBadges.imageActive;
      const roleBadge = this.badgesData.find((badge) => badge.id == 1);
      if (roleBadge) roleBadge.image = medalhaRole;
    },
    updateAppointmentBadgeImage() {
      const medalhaAppointment = this.appointmentBadges.imageActive;
      const appointmentBadge = this.badgesData.find((badge) => badge.id == 2);
      if (appointmentBadge) appointmentBadge.image = medalhaAppointment;
    },
    updateSubscriptionBadgeImage() {
      const medalhaSubscription = this.subscriptionBadges.imageActive;
      const subscriptionBadge = this.badgesData.find((badge) => badge.id == 3);
      if (subscriptionBadge) subscriptionBadge.image = medalhaSubscription;
    },
    updateEpBadgeImage() {
      const medalhaEp = this.epBadges.imageActive;
      const epBadge = this.badgesData.find((badge) => badge.id == 4);
      if (epBadge) epBadge.image = medalhaEp;
    },
    updateVidaBadgeImage() {
      const medalhaVida = this.lifeTotalsBadges.imageActive;
      const vidaBadge = this.badgesData.find((badge) => badge.id == 5);
      if (vidaBadge) vidaBadge.image = medalhaVida;
    },
    updateConsortiumBadgeImage() {
      const medalhaConsortium = this.consortiumBadges.imageActive;
      const consortiumBadge = this.badgesData.find((badge) => badge.id == 6);
      if (consortiumBadge) consortiumBadge.image = medalhaConsortium;
    },
    updateTotalEarningsBadgeImage() {
      const medalhaTotalEarnings = this.totalEarningsBadges.imageActive;
      const totalEarningsBadge = this.badgesData.find((badge) => badge.id == 7);
      if (totalEarningsBadge) totalEarningsBadge.image = medalhaTotalEarnings;
    },
    showModal(badgeId) {
      this.selectedBadgeId = badgeId;
      this.showModalFlag = true;
    },
  },
  mounted() {
    if (this.isPn) {
      this.$nextTick(() => {
        this.getAllBadges();
      });
    }
  },
};
</script>

<style>
.card-placeholder {
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.badge-title {
  display: block;
  padding: 10px;
  font-size: 1rem;
}
</style>

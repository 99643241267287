<template>
  <div>
    <b-row>
      <b-col cols="12">
        <slide-top-dashboard />
      </b-col>
      <b-col v-if="isPn" cols="12">
        <badges-dashboard />
      </b-col>
      <b-col cols="12" class="mb-2">
        <h3>Lembretes</h3>
      </b-col>
      <b-col sm="12" lg="6">
        <ToDoList />
      </b-col>
      <b-col sm="12" lg="6">
        <weekly-schedule />
      </b-col>

      <b-col sm="12" lg="4">
        <birthdays-of-month />
      </b-col>
      <b-col sm="12" lg="4">
        <customers-reminder />
      </b-col>
      <b-col sm="12" lg="4">
        <leads-reminder />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import * as accountTypes from "@/modules/account/store/types";
import * as todoTypes from "@/modules/todo/store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import WeeklySchedule from "../components/WeeklySchedule.vue";
import BirthdaysOfMonth from "../components/BirthdaysOfMonth.vue";
import ToDoList from "@/modules/todo/components/ToDoList.vue";
import CustomersReminder from "../components/CustomersReminder.vue";
import LeadsReminder from "../components/LeadsReminder.vue";
import SlideTopDashboard from "../components/SlideTopDashboard.vue";
import BadgesDashboard from "../components/BadgesDashboard.vue";
import { isPnRole } from "@/constants/auth";

export default {
  components: {
    vSelect,
    WeeklySchedule,
    BirthdaysOfMonth,
    ToDoList,
    LeadsReminder,
    CustomersReminder,
    SlideTopDashboard,
    BadgesDashboard,
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 1,
      searchQuery: "",
      sortField: null,
      order: undefined,
    };
  },
  computed: {
    ...mapGetters({
      user: accountTypes.USER,
    }),
    isPn: function () {
      return isPnRole(this.user?.user_role_id);
    },
  },
  methods: {
    ...mapActions({
      openNewToDoSidebar: todoTypes.OPEN_NEW_TO_DO_SIDEBAR,
    }),
  },
};
</script>

<style lang="scss">
.dash-customer .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.dash-customer .name-content .name {
  width: auto;
}

.dash-customer .name-content .name:hover {
  background-color: #f26237;
}

.dash-customer .whats {
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.dash-customer .whats:hover {
  background-color: #f26237;
}
</style>
